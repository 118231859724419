import React, {Component} from 'react';
import axios from 'axios';

import Navbar from "./../../components/Navbar";
import Footer from "./../../components/Footer";
import DictionaryDialogBox from "./../../pages/dictionary/Dialog";

class App extends Component
{
    state = {
        categories: [],
        category: [],
        isDictionarySelected: false,
        loading: true,
    }

    async componentDidMount() {
        const res = await axios.get(process.env.REACT_APP_API + 'categories');
        if(res.data.status) {
            this.setState({
                categories: res.data.data.data,
                loading: false,
            })
        }

        // Saving Analytic
        // axios.post(process.env.REACT_APP_API + 'analytic', {type: 'button_click', value: 'Dictionary'});
    }

    backDialogBox = () => this.setState({isDictionarySelected: false, category: []})

    dictionarySelected = (category) => {
        this.setState({isDictionarySelected: true, category: category})

        // Saving Analytic
        // axios.post(process.env.REACT_APP_API + 'analytic_category', {category_id: category.id});
    }
    
    render() {
        var categories_html_table = '';
        if(this.state.loading)
        {
            categories_html_table = <li>Loading...</li>
        }
        else
        {
            categories_html_table = 
            this.state.categories.map((item) => {
                return (
                    <li key={item.id} onClick={()=>this.dictionarySelected(item)}>
                        {this.props.enLang?item.en_name:item.mm_name}
                    </li>
                )
            })
        }
        
        return (
            <>
                <Navbar enLang={this.props.enLang} setEnLang={this.props.setEnLang}/>
                <div className="main-content">
                    <ul className="item-list">
                        {categories_html_table}
                    </ul>
                </div>
                {this.state.isDictionarySelected && (
                    <>
                        <DictionaryDialogBox closeBox={this.backDialogBox} data={this.state.category} enLang={this.props.enLang}/>
                    </>
                )}
                <Footer enLang={this.props.enLang} setEnLang={this.props.setEnLang}/>
            </>
        )
    }
}

export default function Index(props) {
    return <App {...props} />;
}