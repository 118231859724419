import React, {Component} from 'react';
import axios from 'axios';

import Item from "./../../pages/dictionary/Item";

class App extends Component
{
    state = {
        definitions: [],
        loading: true,
    }

    async componentDidMount() {
        const res = await axios.get(process.env.REACT_APP_API + 'categories/' + this.props.data.id);
        if(res.data.status) {
            // console.log(res.data.data.definitions)
            this.setState({
                definitions: res.data.data.definitions,
                loading: false,
            })
        }
    }
    
    render() {
        var definitions_html_table = '';
        if(this.state.loading)
        {
            definitions_html_table = <li>Loading...</li>
        }
        else
        {
            definitions_html_table = 
            this.state.definitions.map((item) => {
                return (
                    <Item key={item.id} data={item} enLang={this.props.enLang}/>
                )
            })
        }

        return (
            <>
                <div className="custom-db-reset">
                    <div className="custom-db-shadow"></div>
                    <div className="custom-db-control" style={{width:`${this.props.modalWidth}`,height:`${this.props.modalHeight}`}}>
                        <div className="custom-db-header">
                            <span className="custom-db-header-text">
                                {this.props.enLang?this.props.data.en_name:this.props.data.mm_name}
                            </span>
                            <span className="custom-db-close" onClick={this.props.closeBox}>×</span>
                        </div>
                        <div className="custom-db-body">
                            {definitions_html_table}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default function Index(props) {
    return <App {...props} />;
}