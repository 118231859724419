import React, {Component} from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import MZF from 'mahar-zanaka-foretelling';
import ZT from 'zodiac-telling';
import NK from 'nakhkat-telling';
import CT from 'character-telling';
import NT from 'number-telling';
import HF from 'home-fortune';
import MHB from 'maharbote';
import CZ from 'chinese-zodiac';
import convertEnMmDate from 'english-myanmar-date-converter';
import {num2en} from 'num-2-myanmar-english';
import EnMmDMY from 'en-mm-dmy';

import Navbar from "./../components/Navbar";
import Footer from "./../components/Footer";
import Subscribe from "./../components/Subscribe";
import DialogBox from "./../components/DialogBox";
import Package from "./../components/Package";
import DisplayMZF from './horo/DisplayMZF';
import DisplayNK from './horo/DisplayNK';
import DisplayMHB from './horo/DisplayMHB';
import DisplayCT from './horo/DisplayCT';
import DisplayNT from './horo/DisplayNT';
import DisplayHF from './horo/DisplayHF';
import DisplayZT from './horo/DisplayZT';
import DisplayCZ from './horo/DisplayCZ';

import Celebrity from './celebrity/Index';

class App extends Component
{
    state = {
        h: '',
        dob: new Date(),
        dateSelected: false,
        isDialogOpen: false,
        mzf: [], zt: [], nk: [], ct: [], nt: [], hf: [], cz: [],
        bottom: '',
        celebrity: [],
        dmyObj: {},
    }

    openDialogBox = () => this.setState({isDialogOpen: true})
    closeDialogBox = () => this.setState({isDialogOpen: false, h: '', bottom: ''})

    componentDidMount() {
        this.onChange(new Date())
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.enLang !== this.props.enLang) {
            // console.log(this.props.enLang)
            this.xbaydin(this.state.dob)
        }
    }
    
    onChange = (dob) => {
        const dmyObj = EnMmDMY(dob)
        this.setState({dob: dob, dateSelected: true, celebrity: [], dmyObj: dmyObj})
        this.xbaydin(dob)
    }

    xbaydin = async (dob) => {
        const dmyObj = EnMmDMY(dob)
        console.log(dmyObj)
        const mmDate = convertEnMmDate(dob, 0)
        const mmYear = mmDate.split('၊ ')[1].split(' ')[1].slice(0, -2)

        const d_mzf = MZF(dob)
        const d_nk = NK(mmYear)
        const d_ct = CT(mmYear, dmyObj.mm_day_in_week)
        const d_nt = NT(dmyObj.en_day)
        const d_hf = HF(mmYear)
        const d_zt = ZT(dmyObj.en_month_num, dmyObj.en_day)
        const d_mhb = MHB(num2en(mmYear), dmyObj.en_day_in_week)
        const d_cz = CZ(dmyObj.en_year)
        // console.log(d_cz)

        const mzf = []
        if(d_mzf.status) {
            if(this.props.enLang) {
                mzf.title = `Age '${d_mzf.data.age}', born on '${d_mzf.data.day}' is now reaching '${d_mzf.data.sr}' by Mahajanaka foretelling.`
                mzf.label = `Age range - ${d_mzf.data.sr_age}`
            } else {
                mzf.title = `အသက် '${d_mzf.data.age_mm}' နှစ် '${d_mzf.data.day_mm}' နေ့ဖွားသည် မဟာဇနက္ကဟောကိန်းအရ '${d_mzf.data.sr_mm}' တွင်ရောက်ရှိနေပါသည်။`
                mzf.label = `အသက်အပိုင်းအခြား - ${d_mzf.data.sr_age_mm}`
            }
            mzf.data = d_mzf.data
        } else {
            mzf.title = (this.props.enLang) ? d_mzf.message : d_mzf.message_mm
        }

        const nk = []
        if(d_nk.status) {
            if(this.props.enLang) {
                nk.title = `Myanmar year born in '${num2en(mmYear)}' is '${d_nk.data.nk}' by Nakhkat.`
            } else {
                nk.title = `မြန်မာသက္ကရာဇ် '${mmYear}' ခုနှစ်တွင်မွေးဖွားသူသည် '${d_nk.data.nk_mm}' နက္ခတ် ဖြစ်ပါသည်။`
            }
            nk.data = d_nk.data
        }

        const mhb = []
        if(d_mhb) {
            if(this.props.enLang) {
                mhb.title = `Myanmar year '${num2en(mmYear)}', born in '${dmyObj.en_day_in_week}' is the '${d_mhb.en_name}' by Mahaboat.`
            } else {
                mhb.title = `မြန်မာသက္ကရာဇ် '${mmYear}' ခုနှစ် '${dmyObj.mm_day_in_week}' နေ့တွင်မွေးဖွားသူသည် '${d_mhb.mm_name}' ဖွား ဖြစ်ပါသည်။`
            }
            mhb.data = d_mhb;
        }

        const ct = []
        if(d_ct.status) {
            if(this.props.enLang) {
                ct.title = `Character for whom born in Myanmar year '${num2en(mmYear)}', '${dmyObj.en_day_in_week}' is '${d_ct.data.ct}'`
            } else {
                ct.title = `မြန်မာသက္ကရာဇ် '${mmYear}' ခုနှစ် '${dmyObj.mm_day_in_week}' နေ့ဖွားသည် ပင်ကိုယ်စရိုက်အရ '${d_ct.data.ct_mm}' ဖြစ်ပါသည်။`
            }
            ct.data = d_ct.data
        }

        const nt = []
        if(d_nt.status) {
            if(this.props.enLang) {
                nt.title = `Your number is '${num2en(d_nt.data.nt)}'`
                nt.note = `Related numbers - ${num2en(d_nt.data.nt_note)}`
            } else {
                nt.title = `'${d_nt.data.nt}' ဂဏန်းသမား ဖြစ်ပါသည်။`
                nt.note = `ဆက်စပ်ဂဏန်းများ - ${d_nt.data.nt_note}`
            }
            nt.data = d_nt.data
        }

        const hf = []
        if(d_hf.status) {
            hf.data = d_hf.data
        }

        const zt = []
        if(d_zt.status) {
            if(this.props.enLang) {
                zt.title = `'${d_zt.data.name}' zodiac sign - between ${d_zt.data.dateFrom} and ${d_zt.data.dateTo}`
            } else {
                zt.title = `${d_zt.data.mm_dateFrom} မှ ${d_zt.data.mm_dateTo} အတွင်းမွေးဖွားသူသည် '${d_zt.data.mm_name}' ရာသီဖွား ဖြစ်ပါသည်။`
            }
            zt.data = d_zt.data
        } else {
            zt.title = d_zt.messages
        }

        const cz = []
        if(d_cz) {
            if(this.props.enLang) {
                cz.title = `Born in ${dmyObj.en_year} is '${d_cz.name}'`
            } else {
                cz.title = `${mmYear} ခုနှစ် မွေးဖွားသူသည် '${d_cz.mm_name}' နှစ်ဖွား ဖြစ်ပါသည်။`
            }
            cz.data = d_cz
        }

        this.setState({mzf: mzf})
        this.setState({nk: nk})
        this.setState({mhb: mhb})
        this.setState({ct: ct})
        this.setState({nt: nt})
        this.setState({hf: hf})
        this.setState({zt: zt})
        this.setState({cz: cz})
    }

    showResult = (h) => {
        this.setState({h: h})
        this.openDialogBox()
    }

    showCele = (bottom) => {
        this.setState({bottom: bottom})
        this.openDialogBox()
    }

    celebritySelected = (celebrity) => {
        const dob = new Date(celebrity.dob)
        const dmyObj = EnMmDMY(dob)
        this.closeDialogBox()
        this.setState({dob: dob, dateSelected: true, celebrity: celebrity, dmyObj: dmyObj})
        this.xbaydin(dob)
    }

    render() {
        return (
            <>
                <Navbar enLang={this.props.enLang} setEnLang={this.props.setEnLang}/>
                <div className="main-content">
                    <div className="dob-picker">
                        {
                            this.state.celebrity &&
                                <>
                                    {
                                        this.props.enLang? 
                                            this.state.celebrity.en_name
                                        :
                                            this.state.celebrity.mm_name
                                    }
                                </>
                        }
                        {this.props.enLang? ' Birthday':' မွေးသက္ကရာဇ်'} &nbsp;
                        <div className="calendar">
                            <DatePicker
                                selected={this.state.dob}
                                onChange={(dob) => { this.onChange(dob) }}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                dateFormat="MMMM d, yyyy"
                                withPortal
                                customInput={
                                    <button>
                                        {this.state.dmyObj.en_month} &nbsp;
                                        {this.state.dmyObj.en_day}, &nbsp;
                                        {this.state.dmyObj.en_year}
                                    </button>
                                }
                            />
                        </div>
                    </div>
                    <div className="celebrity">
                        <span  onClick={()=>this.showCele('cele')}>{ this.props.enLang? 'Celebrities':'နာမည်ကြီးသူများ' }</span>
                    </div>

                    {this.state.dateSelected && (
                        <>
                            <div className="package">
                                <Package 
                                    name={this.props.enLang?'Mahajanaka':"မဟာဇနက္က"}
                                    data={this.state.mzf} onShow={()=>this.showResult('mzf')} enLang={this.props.enLang}
                                />
                                <Package 
                                    name={this.props.enLang?'Nakhkat':"နက္ခတ်"}
                                    data={this.state.nk} onShow={()=>this.showResult('nk')} enLang={this.props.enLang}
                                />
                                <Package 
                                    name={this.props.enLang?'Maharbote':"မဟာဘုတ်"}
                                    data={this.state.mhb} onShow={()=>this.showResult('mhb')} enLang={this.props.enLang}
                                />
                                <Package 
                                    name={this.props.enLang?'Character':"ပင်ကိုယ်စရိုက်"}
                                    data={this.state.ct} onShow={()=>this.showResult('ct')} enLang={this.props.enLang}
                                />
                                <Package 
                                    name={this.props.enLang?'Numbers':"ဂဏန်းဗေဒင်"}
                                    data={this.state.nt} onShow={()=>this.showResult('nt')} enLang={this.props.enLang}
                                />
                                {/* <Package 
                                    name={this.props.enLang?'Housing':"အိုးကံ အိမ်ကံ"}
                                    data={this.state.hf} onShow={()=>this.showResult('hf')} enLang={this.props.enLang}
                                /> */}
                                <Package 
                                    name={this.props.enLang?'Zodiac':"ရာသီခွင်"}
                                    data={this.state.zt} onShow={()=>this.showResult('zt')} enLang={this.props.enLang}
                                />
                                <Package 
                                    name={this.props.enLang?'Chinese Zodiac':"တရုတ်ရိုးရာဗေဒင်"}
                                    data={this.state.cz} onShow={()=>this.showResult('cz')} enLang={this.props.enLang}
                                />
                            </div>
                        </>
                    )}
                    {this.state.isDialogOpen && (
                        <>
                            <DialogBox closeBox={this.closeDialogBox} headerText={this.props.enLang?'Prediction':'အဟော'}>
                                <>
                                    <div style={{padding: '0 20px'}}>
                                        {this.state.h === 'mzf' && <DisplayMZF data={this.state.mzf} enLang={this.props.enLang}/>}
                                        {this.state.h === 'nk' && <DisplayNK data={this.state.nk} enLang={this.props.enLang}/>}
                                        {this.state.h === 'mhb' && <DisplayMHB data={this.state.mhb} enLang={this.props.enLang}/>}
                                        {this.state.h === 'ct' && <DisplayCT data={this.state.ct} enLang={this.props.enLang}/>}
                                        {this.state.h === 'nt' && <DisplayNT data={this.state.nt} enLang={this.props.enLang}/>}
                                        {this.state.h === 'hf' && <DisplayHF data={this.state.hf} enLang={this.props.enLang}/>}
                                        {this.state.h === 'zt' && <DisplayZT data={this.state.zt} enLang={this.props.enLang}/>}
                                        {this.state.h === 'cz' && <DisplayCZ data={this.state.cz} enLang={this.props.enLang}/>}
                                    </div>
                                </>
                            </DialogBox>
                        </>
                    )}
                    {this.state.isDialogOpen && (
                        <>
                        {this.state.bottom === 'cele' && (
                            <>
                                <DialogBox closeBox={this.closeDialogBox} headerText={this.props.enLang?'Celebrities':'နာမည်ကြီးသူများ'}>
                                    <Celebrity onCelebritySelected={this.celebritySelected} enLang={this.props.enLang}/>
                                </DialogBox>
                            </>
                        )}
                        </>
                    )}
                </div>
                <Subscribe/>
                <Footer enLang={this.props.enLang} setEnLang={this.props.setEnLang}/>
            </>
        )
    }
}

export default function Home(props) {
    return <App {...props} />;
}