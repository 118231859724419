import React, {Component} from 'react';

class App extends Component
{
    render() {
        return (
            <>
                <div className="custom-db-reset">
                    <div className="custom-db-shadow"></div>
                    <div className="custom-db-control" style={{width:`${this.props.modalWidth}`,height:`${this.props.modalHeight}`}}>
                        <div className="custom-db-header">
                            <span className="custom-db-header-text">{this.props.headerText}</span>
                            <span className="custom-db-close" onClick={this.props.closeBox}>×</span>
                        </div>
                        <div className="custom-db-body">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default function DialogBox(props) {
    return <App {...props} />;
}