import React, {useState} from "react";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Home from "./pages/Home";
import Dictionary from "./pages/dictionary/Index";
import Manomaya from "./pages/manomaya/Index";
import Contact from "./pages/Contact";
import Privacy from "./pages/Privacy";
import Test from "./pages/Test";

function App() {
  const [enLang, setEnLang] = useState(false);

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home enLang={enLang} setEnLang={setEnLang}/>} />
        <Route path='/dictionary' element={<Dictionary enLang={enLang} setEnLang={setEnLang}/>} />
        <Route path='/manomaya-pointer' element={<Manomaya enLang={enLang} setEnLang={setEnLang}/>} />
        <Route path='/contact' element={<Contact enLang={enLang} setEnLang={setEnLang}/>} />
        <Route path='/privacy' element={<Privacy enLang={enLang} setEnLang={setEnLang}/>} />
        <Route path='/test' element={<Test enLang={enLang} setEnLang={setEnLang}/>} />
      </Routes>
    </Router>
  );
}

export default App;