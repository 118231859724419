import React, {Component} from 'react';

class App extends Component
{
    state = {
        active: false,
    }

    toggleActive = (e) => {
        // console.log('toggleActive')
        // console.log(this.state.active)
        const panel = e.target.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
        this.setState({active: !this.state.active})
    }

    render() {
        return (
            <>
                <button className={`accordion ${(this.state.active)?'active':''}`} onClick={(e)=>this.toggleActive(e)}>
                    {this.props.enLang?this.props.data.en_title:this.props.data.mm_title}
                </button>
                <div className="panel">
                    <p style={{margin:'15px 0'}} dangerouslySetInnerHTML={{__html: this.props.data.mm_desc}}/>
                </div>
            </>
        )
    }
}

export default function DialogBox(props) {
    return <App {...props} />;
}