import React, {Component} from 'react';
import axios from 'axios';

class App extends Component
{
    state = {
        celebrities: [],
        loading: true,
    }

    async componentDidMount() {
        const res = await axios.get(process.env.REACT_APP_API + 'celebrities');
        if(res.data.status) {
            this.setState({
                celebrities: res.data.data,
                loading: false,
            })
        }
    }
    
    render() {
        var celebrities_html_table = '';
        if(this.state.loading)
        {
            celebrities_html_table = <li>Loading...</li>
        }
        else
        {
            celebrities_html_table = 
            this.state.celebrities.map((item) => {
                return (
                    <li key={item.id} onClick={()=>this.props.onCelebritySelected(item)}>
                        {this.props.enLang?item.en_name:item.mm_name}
                    </li>
                )
            })
        }
        
        return (
            <>
                <ul className="item-list">
                    {celebrities_html_table}
                </ul>
            </>
        )
    }
}

export default function Index(props) {
    return <App {...props} />;
}