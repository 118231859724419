import React, {Component} from 'react';
import { Link } from "react-router-dom";

class App extends Component
{
    state = {isMenuExpend: false}

    toggleMenu = () => {
        this.setState({isMenuExpend: !this.state.isMenuExpend})
        this.handleScroll() // incase need to sticky back after scrolling on the dialog
    }
    componentDidMount = () => {
        window.addEventListener('scroll', this.handleScroll);

        this.navbar = document.getElementById("myNavbar");
        this.sticky = this.navbar.offsetTop;
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll = () => {
        if (window.pageYOffset >= this.sticky) {
            this.navbar.classList.add("sticky")
        } else {
            this.navbar.classList.remove("sticky");
        }
    }
    render() {
        return (
            <>
                <div className="header">
                    <div className="header-app">
                        <img className="app-logo" src="/favicon.ico" alt="X Baydin"/>
                        <span className="app-name">
                            {this.props.enLang?'xBaydin':'အိတ်ဆောင်ဗေဒင်'}
                        </span>
                    </div>
                    <div className="header-button">
                        <span className="language" onClick={()=>this.props.setEnLang(!this.props.enLang)}>
                            <i className="fa-solid fa-globe"></i> &nbsp;
                            {this.props.enLang?'English':'မြန်မာ'}
                        </span>
                    </div>
                </div>
                <div className={`navbar ${(this.state.isMenuExpend)?'responsive':''}`} id="myNavbar">
                    <Link to="/" className={(window.location.pathname === '/') ? 'active':''}>
                        <i className="fa-solid fa-house-chimney"></i>
                    </Link>
                    <Link to="/dictionary" className={(window.location.pathname === '/dictionary') ? ' active':''}>
                        {this.props.enLang?'Sign & Foretelling':'နိမိတ် နှင့် ကျမ်းများ'}
                    </Link>
                    {/* <Link to="/practice" className={(window.location.pathname === '/practice') ? ' active':''}>
                        {this.props.enLang?'Wish & Practice':'အဓိဌာန် နှင့် ကျင့်စဉ်'}
                    </Link> */}
                    {/* <Link to="/manomaya" className={(window.location.pathname === '/manomaya') ? ' active':''}>
                        {this.props.enLang?'Manomaya Pointer':'မနောမယ လက်ထောက်ဗေဒင်'}
                    </Link> */}
                    <Link to="/contact" className={(window.location.pathname === '/contact') ? ' active':''}>
                        {this.props.enLang?'Contact Us':'ဆက်သွယ်ရန်'}
                    </Link>
                    <Link to="#" className="icon" onClick={()=>this.toggleMenu()}>
                        { this.state.isMenuExpend ? <>&times;</> : <>&#9776;</> }
                    </Link>
                </div>
            </>
        )
    }
}

export default function Navbar(props) {
    return <App {...props} />;
}