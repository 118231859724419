import React, {Component} from 'react';

class App extends Component
{
    render() {
        return (
            <>
                <footer>
                    <div className="copy">
                        &copy; 2022  {this.props.enLang?'xBaydin':'အိတ်ဆောင်ဗေဒင်'}
                    </div>
                    {/* <div className="links">
                        <Link to='/contact'>
                            {this.props.enLang?'Contact Us':'ဆက်သွယ်ရန်'}
                        </Link>
                        &nbsp; | &nbsp;
                        <Link to='/privacy'>Privacy</Link>
                    </div> */}
                </footer>
            </>
        )
    }
}

export default function Footer(props) {
    return <App {...props} />;
}