import React, {Component} from 'react';
import axios from 'axios';

import Navbar from "./../components/Navbar";
import Footer from "./../components/Footer";

class App extends Component
{
    componentDidMount = () => {
        // Saving Analytic
        axios.post(process.env.REACT_APP_API + 'analytic', {type: 'button_click', value: '/privacy'});
    }

    render() {
        return (
            <>
                <Navbar enLang={this.props.enLang} setEnLang={this.props.setEnLang}/>
                <div className="main-content privacy">
                    <h3>Privacy Notice</h3>
                    <ul style={{margin: '20px'}}>
                        <li>
                            Third party vendors, including Google, use cookies to serve ads based on your prior visits to this website or other websites.
                        </li>
                        <li>
                            Google's use of advertising cookies enables it and its partners to serve ads to you based on your visit to websites on the Internet.
                        </li>
                    </ul>
                </div>
                <Footer enLang={this.props.enLang} setEnLang={this.props.setEnLang}/>
            </>
        )
    }
}

export default function Privacy(props) {
    return <App {...props} />;
}