import React, {Component} from 'react';
import DatePicker from 'rmc-date-picker';
import 'rmc-picker/assets/index.css';
import 'rmc-date-picker/assets/index.css';

class App extends Component
{
    state = {
        date: new Date(2017, 1, 25), // Year, Month start with 0, Day
        maxDate: new Date(2022, 11, 31),
        minDate: new Date(1900, 0, 1),
    }

    onDateChange = (date) => {
        console.log('onChange', date);
        this.setState({
            date,
        });
    }

    onValueChange = (values, index) => {
        console.log('onValueChange', values, index);
    }

    onScrollChange = (values, index) => {
        console.log('onScrollChange', values, index);
    }

    render() {
        return (
            <>
                <DatePicker
                    defaultDate={this.state.date}
                    mode={'date'}
                    maxDate={this.state.maxDate}
                    minDate={this.state.minDate}
                    onDateChange={this.onDateChange}
                    onValueChange={this.onValueChange}
                    onScrollChange={this.onScrollChange}
                    use12Hours
                />
            </>
        )
    }
}

export default function Test(props) {
    return <App {...props} />;
}