import React, {Component} from 'react';
import axios from 'axios';

class App extends Component
{
    state = {
        email: '',
        subscribed: '',
        errors: [],
    }

    handleInput = async (e) => {
        // console.log(e.target.name)
        if(e.target.name === 'email') {
            this.setState({
                email: e.target.value
            })
        }
    }

    saveSubscriber = async (e) => {
        e.preventDefault();
        let btnSubscribe = document.getElementById('btnSubscribe');
        btnSubscribe.disabled = true;
        btnSubscribe.innerText = 'Subscribing...';
        
        const res = await axios.post(process.env.REACT_APP_API + 'subscriber', {email: this.state.email});
        if(res.data.status)
        {
            this.setState({
                email: '',
                subscribed: res.data.message,
                errors: [],
            })
        }
        else
        {
            this.setState({
                subscribed: '',
                errors: res.data.errors,
            })
        }
        btnSubscribe.innerText = 'Subscribe';
        btnSubscribe.disabled = false;
    }

    render() {
        return (
            <>
                <div className="subscribe">
                    <p>Get email of all the updates about our latest and special offers</p>
                    <div className="input-btn">
                        <input type="text" name="email" value={this.state.email} onChange={this.handleInput} placeholder='Email'/>
                        <button type="button" id="btnSubscribe" onClick={(e)=>this.saveSubscriber(e)}>Subscribe</button>
                    </div>
                    <span className='text-warning'>{this.state.errors.email}</span>
                    <span className='text-info'>{this.state.subscribed}</span>
                </div>
            </>
        )
    }
}

export default function Subscribe(props) {
    return <App {...props} />;
}