import React, {Component} from 'react';
import axios from 'axios';

import Navbar from "./../components/Navbar";
import Footer from "./../components/Footer";

class App extends Component
{
    state = {
        email: '',
        title: '',
        description: '',
        loading: true,
        sent: '',
        errors: [],
    }

    componentDidMount = () => {
        // Saving Analytic
        axios.post(process.env.REACT_APP_API + 'analytic', {type: 'button_click', value: '/contact'});
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    saveContact = async (e) => {
        e.preventDefault();
        let btnSend = document.getElementById('btnSend');
        btnSend.disabled = true;
        btnSend.innerText = 'Sending...';
        
        const res = await axios.post(process.env.REACT_APP_API + 'contact', this.state);
        if(res.data.status)
        {
            this.setState({
                email: '',
                title: '',
                description: '',
                sent: res.data.message,
                errors: [],
            })
            // below is because state does not work for textarea
            document.getElementById('txtDescription').value = '';
        }
        else
        {
            this.setState({
                sent: '',
                errors: res.data.errors,
            })
        }
        btnSend.innerText = 'Send';
        btnSend.disabled = false;
    }
    
    render() {
        return (
            <>
                <Navbar enLang={this.props.enLang} setEnLang={this.props.setEnLang}/>
                <div className="main-content contact">
                    <h3>Contact Us</h3>
                    <div className="form">
                        <form onSubmit={this.saveContact}>
                            <div className='form-group'>
                                <label>Email</label>
                                <input type="text" name="email" onChange={this.handleInput} value={this.state.email} className='form-control' />
                                <span className='text-warning'>{this.state.errors.email}</span>
                            </div>
                            <div className='mt-3 form-group'>
                                <label>Title</label>
                                <input type="text" name="title" onChange={this.handleInput} value={this.state.title} className='form-control' />
                                <span className='text-warning'>{this.state.errors.title}</span>
                            </div>
                            <div className='mt-3 form-group'>
                                <label>Description</label>
                                <textarea name="description" onChange={this.handleInput} defaultValue={this.state.description} className="form-control" rows="10" id="txtDescription"></textarea>
                                <span className='text-warning'>{this.state.errors.description}</span>
                            </div>

                            <div className='mt-3 form-group'>
                                <button type='submit' id='btnSend' className='btn btn-primary'>
                                    <i className='fa-solid fa-share'></i> Send
                                </button>
                                <span className='text-info'>&nbsp; {this.state.sent}</span>
                            </div>
                        </form>
                    </div>
                </div>
                <Footer enLang={this.props.enLang} setEnLang={this.props.setEnLang}/>
            </>
        )
    }
}

export default function Contact(props) {
    return <App {...props} />;
}