import React, {Component} from 'react';
import axios from 'axios';
import nl2br from 'nl2br';

class App extends Component
{
    state = {
        result: '',
        loading: true,
    }
    componentDidMount = async () => {
        const postData = {enLang: this.props.enLang, key: 'ct', value: this.props.data.data.ct_mm}
        const res = await axios.post(process.env.REACT_APP_API + 'horoscope', postData)
        if(res.data.status) {
            this.setState({
                result: res.data.data,
                loading: false,
            })
        }
    }
    render() {
        return (
            <>
                <h3>{this.props.data.title}</h3>
                {this.state.loading && 'Loading...'}
                <p className="horoscope-result" dangerouslySetInnerHTML={{__html: nl2br(this.state.result)}}/>
            </>
        )
    }
}

export default function DisplayCT(props) {
    return <App {...props} />;
}