import React, {Component} from 'react';

class App extends Component
{
    state = {
        active: false,
    }

    // componentDidMount = () => {
    //   console.log(this.props.data)
    // }

    toggleActive = (e) => {
        // console.log('toggleActive')
        // console.log(this.state.active)
        const panel = e.target.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px"
        }
        this.setState({active: !this.state.active})
    }

    render() {
        return (
            <>
                <button className={`accordion ${(this.state.active)?'active':''}`} onClick={(e)=>this.toggleActive(e)}>{this.props.name}</button>
                <div className="panel">
                    {this.props.data.title}
                    {this.props.data.title?<br/>:''}
                    {
                        this.props.data.data &&
                        <>
                            <button className="button" onClick={this.props.onShow}>
                                {this.props.enLang?'Prediction':'အဟော'}
                            </button>
                        </>
                    }
                </div>
            </>
        )
    }
}

export default function DialogBox(props) {
    return <App {...props} />;
}